import React from 'react'
import { css } from 'linaria'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Section from '../components/Section'
import SectionContent from '../components/Section/Content'
import { Lead, HeroHeading } from '../components/TextElements'
import Button from '../components/Button'

const Page = ({ location }) => (
  <Layout
    location={location}
    title="404 Error - Bejamas"
    description="Something went wrong."
  >
    <Section
      header={
        <>
          <HeroHeading>We cincerely apologize.</HeroHeading>
          <Lead>The page you are looking for does not exist.</Lead>
          <Button to="/">Go to homepage</Button>
        </>
      }
    >
      <SectionContent columns="1"></SectionContent>
    </Section>
  </Layout>
)

export default Page
